import React from "react"
import styled from "styled-components"
import Footer from "../../components/profissionaisSaude/Footer"
import Header from "../../components/profissionaisSaude/Header"
import GoodbyeImg from "../../assets/images/desativar-conta.svg"
import Button from "../../components/profissionaisSaude/Inputs/Button"

export default function DesativarConta() {
  const Content = styled.section`
    width: 100%;
    max-width: 68.75rem;
    min-height: calc(100vh - 13.25rem);
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    padding: 3rem 0 4.25rem;
    gap: 1.375rem;

    @media screen and (max-width: 1020px) {
      padding: 1rem 2.5rem;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
  `

  const ContentInfos = styled.div`
    max-width: 445px;
  `

  const ImageWrapper = styled.div`
    position: relative;
    max-width: 475px;
    max-height: 392px;
  `

  const Image = styled(GoodbyeImg)`
    max-width: 475px;
    max-height: 392px;
    position: relative;
  `

  const ImageCredit = styled.span`
    color: #515151;
    font-size: 0.75rem;
    position: absolute;
    bottom: 1.9rem;
    right: 2rem;
  `

  const ImageCreditLink = styled.a`
    text-decoration: underline;
  `

  const Heading = styled.div`
    width: 100%;
    color: #1f1f1f;
    font-family: "Nunito";
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  `

  const Text = styled.p`
    font-family: "Nunito";
    font-size: 16px;
    color: #1f1f1f;
    margin-bottom: 32px;
  `

  return (
    <>
      <Header />
      <Content>
        <ContentInfos>
          <Heading>Conta desativada temporariamente!</Heading>
          <Text>
            Sua conta de profissional da Saúde Lacrei foi suspensa e não
            aparecerá na busca.
          </Text>
          <Text>
            Você pode reativá-la novamente a qualquer momento. É só procurar por
            Reativar Conta em Segurança e Privacidade.
          </Text>
          <Button title="Voltar para o painel" />
        </ContentInfos>
        <ImageWrapper>
          <Image />
          <ImageCredit>
            Ilustrado por{" "}
            <ImageCreditLink target={"_blank"} href="https://br.freepik.com/">
              Freepik
            </ImageCreditLink>
          </ImageCredit>
        </ImageWrapper>
      </Content>
      <Footer />
    </>
  )
}
